import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { debounce } from 'lodash';
import './App.css';
import Controls from './Components/Controls/Controls';
import Profile from './Components/Profile/Profile';
import SplashScreen from './Components/Loading/Loading';
import ControlsBar from './Components/Controls/ControlsBar';

const ITYPE = () => {
  const [text, setText] = useState(() => localStorage.getItem('itype-text') || '');
  const [currentSuggestion, setCurrentSuggestion] = useState('');
  const [fontSize, setFontSize] = useState(() => parseInt(localStorage.getItem('itype-fontSize')) || 80);
  const [wordCount, setWordCount] = useState(0);
  const [charCount, setCharCount] = useState(0);
  const [showWordCount, setShowWordCount] = useState(true);
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const [loading, setLoading] = useState(true);
  const [fadeOut, setFadeOut] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 420);
  const [isReady, setIsReady] = useState(false);

  const contentRef = useRef(null);
  const controlBarRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    localStorage.setItem('itype-fontSize', fontSize.toString());
    
    // Update word and character count
    const trimmedText = text.trim();
    const words = trimmedText.split(/\s+/);
    setWordCount(trimmedText ? words.length : 0);
    setCharCount(text.length);

    // Smoother cinematic font size adjustment
    if (text.length > 25) {
      // Logarithmic decrease for smoother scaling
      const decrease = Math.log(text.length - 24) * 8;
      const newSize = Math.max(25, 80 - decrease);
      setFontSize(newSize);
    } else {
      setFontSize(80); // Reset to default size for short text
    }
  }, [text, fontSize]);

  useEffect(() => {
    const handleKeyboardShortcuts = (e) => {
      if (e.altKey && e.key.toLowerCase() === 'w') {
        e.preventDefault();
        setShowWordCount(prev => !prev);
        return;
      }

      if (e.ctrlKey || e.metaKey) {
        switch(e.key.toLowerCase()) {
          case '+':
          case '=':
            e.preventDefault();
            setFontSize(prev => Math.min(prev + 2, 120));
            break;
          case '-':
            e.preventDefault();
            setFontSize(prev => Math.max(prev - 2, 40));
            break;
          default:
            break;
        }
      }
    };

    document.addEventListener('keydown', handleKeyboardShortcuts);
    return () => document.removeEventListener('keydown', handleKeyboardShortcuts);
  }, []);

  const handleEditableInput = useCallback((e) => {
    if (!contentRef.current || !isReady) return;
    
    const newText = e.target.innerText;
    setText(newText);
    debouncedFetchSuggestions(newText);
  }, [isReady]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 580) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    window.addEventListener('resize', handleResize);

    // Initial check
    handleResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setFadeOut(true);
      setTimeout(() => {
        setLoading(false);
      }, 500); // Match this timeout to the CSS transition duration
    }, 2000); // Simulate a 3-second loading time

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if ('virtualKeyboard' in navigator) {
      navigator.virtualKeyboard.overlaysContent = true;
      navigator.virtualKeyboard.hide();
    }
  }, []);

  useEffect(() => {
    if (!isReady || !containerRef.current) return;

    const updateOverflow = () => {
      const container = containerRef.current;
      if (container && fontSize === 25) {  
        if (container.scrollHeight > container.clientHeight) {
          container.style.overflowY = 'auto';
        } else {
          container.style.overflowY = 'unset';
        }
      } else if (container) {
        container.style.overflowY = 'unset';  
      }
    };

    const timer = setTimeout(updateOverflow, 100);
    return () => clearTimeout(timer);
  }, [text, isReady, fontSize]);

  useEffect(() => {
    const updateCursorPosition = () => {
      if (!isReady) return;
      const selection = window.getSelection();
      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const rect = range.getBoundingClientRect();
        setCursorPosition({
          x: rect.right + window.scrollX + 10,
          y: rect.top + window.scrollY - 20
        });
      }
    };

    if (isReady) {
      document.addEventListener('selectionchange', updateCursorPosition);
      return () => document.removeEventListener('selectionchange', updateCursorPosition);
    }
  }, [isReady]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsReady(true);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  const debouncedFetchSuggestions = useCallback(
    debounce(async (text) => {
      try {
        const response = await axios.post('/.netlify/functions/prediction', { text });
        const newSuggestion = response.data.response.candidates.replace(/[\n↵*]/g, '').trim() || '';
        setCurrentSuggestion(newSuggestion);
      } catch (error) {
        console.error('Error fetching suggestions from Netlify function:', error);
      }
    }, 800),
    []
  );

  const moveCursorToEnd = useCallback(() => {
    if (!contentRef.current || !isReady) return;
    
    const range = document.createRange();
    const selection = window.getSelection();
    range.selectNodeContents(contentRef.current);
    range.collapse(false);
    selection.removeAllRanges();
    selection.addRange(range);
  }, [isReady]);

  const moveCursorOneStepLeft = useCallback(() => {
    if (!contentRef.current || !isReady) return;

    const selection = window.getSelection();
    if (selection.rangeCount) {
      const range = selection.getRangeAt(0);
      range.setStart(range.startContainer, Math.max(0, range.startOffset - 1));
      range.collapse(true);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }, [isReady]);

  const moveCursorOneStepRight = useCallback(() => {
    if (!contentRef.current || !isReady) return;

    const selection = window.getSelection();
    if (selection.rangeCount) {
      try {
        selection.modify("move", "forward", "character");
      } catch (error) {
        // Handle any unexpected errors gracefully
        console.error('Error moving cursor right:', error);
        const range = selection.getRangeAt(0);
        selection.removeAllRanges();
        range.setStart(range.startContainer, range.startOffset + 1);
        selection.addRange(range);
      }
    }
  }, [isReady]);

  useEffect(() => {
    if (text.length > 0) {
      debouncedFetchSuggestions(text);
    } else {
      setCurrentSuggestion('');
      if (contentRef.current && isReady) {
        contentRef.current.focus();
      }
    }
  }, [text, debouncedFetchSuggestions, isReady]);

  useEffect(() => {
    if (!isReady || !containerRef.current) return;

    const updateOverflow = () => {
      const container = containerRef.current;
      if (container && fontSize === 25) {  
        if (container.scrollHeight > container.clientHeight) {
          container.style.overflowY = 'auto';
        } else {
          container.style.overflowY = 'unset';
        }
      } else if (container) {
        container.style.overflowY = 'unset';  
      }
    };

    const timer = setTimeout(updateOverflow, 100);
    return () => clearTimeout(timer);
  }, [text, isReady, fontSize]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.ctrlKey || event.metaKey) && (event.key === 'c' || event.key === 'v' || event.key === 'x' || event.key === 'a')) {
        event.preventDefault();
      }

      // Handle Ctrl+Shift+ArrowRight separately
      if (event.ctrlKey && event.shiftKey && event.key === 'ArrowRight') {
        event.preventDefault();
        event.stopPropagation();
        setText((prevText) => {
          const newText = prevText + currentSuggestion.slice(prevText.length);
          if (contentRef.current) {
            contentRef.current.innerText = newText;
          }
          setTimeout(() => {
            if (contentRef.current && isReady) {
              moveCursorToEnd();
            }
          }, 0);
          setCurrentSuggestion('');
          return newText;
        });
        return;
      }

      // Handle regular ArrowRight
      if (event.key === 'ArrowRight' && !event.ctrlKey && !event.shiftKey) {
        event.preventDefault();
        setText((prevText) => {
          const nextChar = currentSuggestion.charAt(text.length);
          const newText = prevText + nextChar;
          if (contentRef.current) {
            contentRef.current.innerText = newText;
          }
          setTimeout(() => {
            if (contentRef.current && isReady) {
              moveCursorToEnd();
            }
          }, 0);
          return newText;
        });
        return;
      }

      // Prevent multiple spaces
      if (event.key === ' ' && text.endsWith(' ')) {
        event.preventDefault();
      }
      // Prevent multiple newlines
      if (event.key === 'Enter' && text.endsWith('\n')) {
        event.preventDefault();
      }

      // Temporarily prevent Enter key
      if (event.key === 'Enter') {
        event.preventDefault();
      }

      if (event.key === 'ArrowLeft') {
        event.preventDefault();

        setText((prevText) => {
          const newText = prevText.slice(0, -1); // Calculate the new text here
          if (contentRef.current) {
            contentRef.current.innerText = newText;
          }

          // Use `newText` to set the cursor position
          setTimeout(() => {
            if (contentRef.current && isReady) {
              const selection = window.getSelection();
              const range = document.createRange();
              range.setStart(contentRef.current.childNodes[0], newText.length); // Set the cursor to the correct position
              range.collapse(true);
              selection.removeAllRanges();
              selection.addRange(range);
            }
          }, 0);

          return newText; // Return the updated text for the state update
        });
      }

      if (event.key === 'ArrowUp') {
        event.preventDefault();
        // Refetch new suggestion
        debouncedFetchSuggestions(text);
      }

      if (event.key === 'ArrowDown') {
        event.preventDefault();
        // Clear suggestions
        setCurrentSuggestion('');
      }

      if (event.ctrlKey && event.key === 's') {
        event.preventDefault();
        // Generate title and save the text
        generateTitleAndSave();
      }

      if (event.ctrlKey && event.shiftKey && event.key === 'S') {
        event.preventDefault();
        // Share the text with web share API
        if (navigator.share) {
          navigator.share({
            title: 'Written with ITYPE',
            text: text,
          });
        }
      }

      // Move cursor with page up and page down keys
      if (event.key === 'PageUp' || event.key === 'PageDown') {
        if (event.key === 'PageUp') {
          moveCursorOneStepLeft();
          event.preventDefault();
        } else {
          moveCursorOneStepRight();
          event.preventDefault();
        }
      }
    };

    const handleDragStart = (event) => {
      event.preventDefault();
    };

    const currentContentRef = contentRef.current;
    window.addEventListener('keydown', handleKeyDown);
    if (currentContentRef && isReady) {
      currentContentRef.addEventListener('dragstart', handleDragStart);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      if (currentContentRef && isReady) {
        currentContentRef.removeEventListener('dragstart', handleDragStart);
      }
    };
  }, [text, currentSuggestion, debouncedFetchSuggestions, moveCursorToEnd, moveCursorOneStepLeft, moveCursorOneStepRight, isReady]);

  const handleInput = useCallback((inputText) => {
    if (!contentRef.current || !isReady) return;
    
    setText(inputText);
    if (contentRef.current) {
      contentRef.current.innerText = inputText;
    }
    debouncedFetchSuggestions(inputText);
  }, [isReady]);

  const handleAcceptChar = () => {
    if (!contentRef.current || !isReady) return;

    const nextChar = currentSuggestion.charAt(text.length);
    const newText = text + nextChar;
    setText(newText);
    handleInput(newText);
  };
  
  const handleAcceptWord = () => {
    if (!contentRef.current || !isReady) return;

    const nextWord = currentSuggestion.slice(text.length).split(' ')[0] + ' ';
    const newText = text + nextWord;
    setText(newText);
    handleInput(newText);
  };
  
  const handleAcceptSuggestion = () => {
    if (!contentRef.current || !isReady) return;

    const newText = text + currentSuggestion.slice(text.length);
    setText(newText);
    setCurrentSuggestion('');
    handleInput(newText);
  };
  
  const generateTitleAndSave = async () => {
    try {
      // Save to localStorage
      localStorage.setItem('itype-text', text);

      const titleResponse = await axios.post('/.netlify/functions/generate-title', { text });
      const title = titleResponse.data.title;

      const reformatResponse = await axios.post('/.netlify/functions/reformat-text', { title, text });
      const formattedText = reformatResponse.data.formattedText;

      // Save the text using the browser's save dialog
      const blob = new Blob([formattedText], { type: 'text/plain' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${title}.txt`
      a.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error generating title and saving the text:', error);
    }
  };

  const getSuggestedText = () => {
    return currentSuggestion.slice(text.length);
  };

  return (
    <div className="app-container">
      {loading ? (
        <SplashScreen fadeOut={fadeOut} />
      ) : (
        <>
          {isReady && showWordCount && !isMobile && (
            <div 
              className={`word-count ${text.length > 0 ? 'visible' : ''}`}
              style={{ 
                position: 'absolute',
                left: `${cursorPosition.x}px`,
                top: `${cursorPosition.y}px`,
                fontSize: '14px',
                pointerEvents: 'none'
              }}
            >
              {wordCount} words · {charCount} chars
            </div>
          )}
          <h1 className='logo'>I<span style={{ fontWeight: "100" }}>|</span><span style={{ opacity: "0.3" }}>TYPE</span></h1>
          <div className="typewriter-restriction">
            <div className="typewriter-container" style={{ fontSize: `${fontSize}px` }} ref={containerRef}>
              <div className="typewriter-wrapper">
                <div className="typewriter-input-wrapper">
                  <div className="typewriter-suggestion">
                    {text + getSuggestedText()}
                  </div>
                  <div
                    virtualKeyboardPolicy="manual"
                    ref={contentRef}
                    contentEditable
                    onInput={handleEditableInput}
                    onFocus={(e) => {
                      if (isMobile) {
                        e.target.blur();
                      } else if (isReady) {
                        e.target.focus();
                      }
                    }}
                    className="typewriter-input"
                    style={{ fontSize: `${fontSize}px` }}
                  />
                </div>
              </div>
            </div>
          </div>
          <Controls 
            wordCount={wordCount}
            charCount={charCount}
            showWordCount={showWordCount}
            text={text}
          />
          <ControlsBar
            ref={controlBarRef}
            fontSize={fontSize}
            setFontSize={setFontSize}
            onChange={handleInput}
            currentText={text} 
            onSave={generateTitleAndSave}
            onShare={() => {
              if (navigator.share) {
                navigator.share({
                  title: 'Written with ITYPE',
                  text: text,
                });
              }
            }}
            onAcceptChar={handleAcceptChar}
            onAcceptWord={handleAcceptWord}
            onAcceptSuggestion={handleAcceptSuggestion}
            onDelete={() => {
              setText((prevText) => {
                if (!contentRef.current || !isReady) return prevText;
                const newText = prevText.slice(0, -1);
                contentRef.current.innerText = newText;
                moveCursorToEnd();
                return newText;
              });
            }}
            onRefetch={() => {
              debouncedFetchSuggestions(text);
            }}
            onDiscardSuggestion={() => {
              setCurrentSuggestion('');
            }}
          />
        </>
      )}
    </div>
  );
};

export default ITYPE;
